import React from "react";
import Model from "../../components/Model";

const Index = () => {
    return <div className="d-flex h-100 w-100 align-items-start justify-content-start">
        <div
            className="d-flex m-3 mx-sm-auto mx-md-3 flex-column flex-nowrap gap-3 bg-white position-relative p-4 rounded shadow-lg font-monospace"
            style={{
                zIndex: 20
            }}>
            <h1 className="fs-3 m-0">Alexander Matveev</h1>
            <div className="d-flex flex-row flex-wrap gap-2 align-items-center justify-content-start">
                <i className="bi bi-heart-fill text-danger"/>
                <span className="badge bg-light text-body border">Golang</span>
                <span className="badge bg-light text-body border">React</span>
            </div>
            <div className="d-flex flex-row flex-wrap gap-2 align-items-center justify-content-start">
                <i className="bi bi-heart-half text-danger"/>
                <span className="badge bg-light text-body border">Swift</span>
                <span className="badge bg-light text-body border">Kotlin</span>
                <span className="badge bg-light text-body border">Flutter</span>
            </div>
            <hr/>
            <div className="d-flex flex-row flex-wrap gap-3">
                <a className="btn btn-outline-primary btn-sm" href="https://github.com/AlexanderMatveev" target={"_blank"}
                   rel={"noreferrer"}>
                    <i className="bi bi-github me-1"/>GitHub
                </a>
                <a className="btn btn-outline-primary btn-sm" href="https://www.linkedin.com/in/alexandermatveev/"
                   target={"_blank"} rel={"noreferrer"}>
                    <i className="bi bi-linkedin me-1"/>LinkedIn
                </a>
                <a className="btn btn-outline-primary btn-sm" href="https://www.facebook.com/matveev.alex"
                   target={"_blank"} rel={"noreferrer"}>
                    <i className="bi bi-facebook me-1"/>Facebook
                </a>
            </div>
        </div>
        <div className="position-fixed" style={{
            zIndex: 10
        }}>
            <Model/>
        </div>
    </div>
};

export default Index;