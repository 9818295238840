import React, {useEffect, useRef} from "react";
import {PCDLoader} from "three/examples/jsm/loaders/PCDLoader";
import {PerspectiveCamera, PointsMaterial, Scene, WebGLRenderer} from "three";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";

const Index = () => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    useEffect(() => {
        if (canvasRef === null || canvasRef.current === null) {
            console.dir("no vcanvas");
            return;
        }
        console.dir("Starting");
        let camera: PerspectiveCamera;
        let scene: Scene;
        let renderer: WebGLRenderer;
        init();
        render();

        function init() {
            renderer = new WebGLRenderer({
                antialias: true,
                alpha: true,
                canvas: canvasRef.current as HTMLCanvasElement
            });
            renderer.setClearColor(0x000000, 0); // the default
            renderer.setPixelRatio(window.devicePixelRatio);
            renderer.setSize(window.innerWidth, window.innerHeight);
            scene = new Scene();
            camera = new PerspectiveCamera(30, window.innerWidth / window.innerHeight, 0.01, 40);
            camera.position.set(0, 0, 1);
            scene.add(camera);
            const controls = new OrbitControls(camera, renderer.domElement);
            controls.addEventListener('change', render); // use if there is no animation loop
            controls.minDistance = 0.5;
            controls.maxDistance = 10;
            const loader = new PCDLoader();
            loader.load('/Zaghetto.pcd', function (points) {
                (points.material as PointsMaterial).color.setHex(0xffffff);
                (points.material as PointsMaterial).size = 0.003;
                points.geometry.center();
                points.geometry.rotateX(Math.PI);
                scene.add(points);
                render();

            });
            window.addEventListener('resize', onWindowResize);
        }

        function onWindowResize() {
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();
            renderer.setSize(window.innerWidth, window.innerHeight);
        }

        function render() {
            renderer.render(scene, camera);
        }

        return () => {
            window.removeEventListener('resize', onWindowResize);
        };
    }, []);
    return <canvas ref={canvasRef}/>
}

export default Index;