import React from 'react';
import './App.scss';
import 'bootstrap';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import Home from './pages/Home';

function App() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" exact={true} component={Home}/>
                <Route>
                    <Redirect to={"/"}/>
                </Route>
            </Switch>
        </BrowserRouter>
    );
}

export default App;
